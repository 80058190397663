@keyframes fadeIn {
    0% { opacity: 0; }
    80% { opacity: 0.1; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    80% { opacity: 0.1; }
    100% { opacity: 1; }
}

.footer-builder.next-btn button {
    width: 120px;

}

.footer-builder.next-btn {
    padding: 10px;
}

.footer-builder.prev-btn {
    padding: 10px;
}

.footer-builder.prev-btn button {
    width: 120px;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100vh);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
    }
  }

.dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    z-index: 99999;
    padding: 24px 0;
    color: #fff;
    height: auto;
    background-color: #000000;
    border-radius: 33px 33px 0 0;
    align-items: center;
    right: 0;
    position: fixed;
    bottom: 0;
    transition: bottom .3s ease-in-out;
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
    width: 100%;
}

.dialog-description {
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Gentleman', Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 134%;
    color: #FFFFFF;
    text-align: left;
    max-width: 600px;
    width: 100%;
    display: block;
}

.dialog-description.title-green {
    font-family: 'Gentleman', Arial, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 17px;
    line-height: 118%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #00D154;
    margin-bottom: 20px;
}

.dialog-description a {
    color: #FFFFFF;
    text-decoration: underline;
}

.dialog.dialogOut {
    bottom: -100%;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(100vh);
    }
}

@keyframes fadeInDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh);
    }
}

@media screen and (max-width: 710px) {
    .dialog {
        margin-bottom: -1rem;
    }
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/fonts/BrandonGrotesque-Light.eot');
  src: url('./assets/fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BrandonGrotesque-Light.woff2') format('woff2'),
    url('./assets/fonts/BrandonGrotesque-Light.woff') format('woff'),
    url('./assets/fonts/BrandonGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/fonts/BrandonGrotesque-Regular.eot');
  src: url('./assets/fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
    url('./assets/fonts/BrandonGrotesque-Regular.woff') format('woff'),
    url('./assets/fonts/BrandonGrotesque-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/fonts/BrandonGrotesque-Medium.eot');
  src: url('./assets/fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
    url('./assets/fonts/BrandonGrotesque-Medium.woff') format('woff'),
    url('./assets/fonts/BrandonGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/fonts/BrandonGrotesque-Bold.eot');
  src: url('./assets/fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
    url('./assets/fonts/BrandonGrotesque-Bold.woff') format('woff'),
    url('./assets/fonts/BrandonGrotesque-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./assets/fonts/BrandonGrotesque-Black.eot');
  src: url('./assets/fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/BrandonGrotesque-Black.woff2') format('woff2'),
    url('./assets/fonts/BrandonGrotesque-Black.woff') format('woff'),
    url('./assets/fonts/BrandonGrotesque-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentleman';
  src: url('./assets/fonts/Gentleman-Light.eot');
  src: url('./assets/fonts/Gentleman-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gentleman-Light.woff2') format('woff2'),
    url('./assets/fonts/Gentleman-Light.woff') format('woff'),
    url('./assets/fonts/Gentleman-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentleman';
  src: url('./assets/fonts/Gentleman-Book.eot');
  src: url('./assets/fonts/Gentleman-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gentleman-Book.woff2') format('woff2'), url('./assets/fonts/Gentleman-Book.woff') format('woff'),
    url('./assets/fonts/Gentleman-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentleman';
  src: url('./assets/fonts/Gentleman-Regular.eot');
  src: url('./assets/fonts/Gentleman-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gentleman-Regular.woff2') format('woff2'),
    url('./assets/fonts/Gentleman-Regular.woff') format('woff'),
    url('./assets/fonts/Gentleman-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentleman';
  src: url('./assets/fonts/Gentleman-Medium.eot');
  src: url('./assets/fonts/Gentleman-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gentleman-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gentleman-Medium.woff') format('woff'),
    url('./assets/fonts/Gentleman-Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gentleman';
  src: url('./assets/fonts/Gentleman-Bold.eot');
  src: url('./assets/fonts/Gentleman-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gentleman-Bold.woff2') format('woff2'), url('./assets/fonts/Gentleman-Bold.woff') format('woff'),
    url('./assets/fonts/Gentleman-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/poppins-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/poppins/poppins-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/poppins/poppins-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/roboto/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/roboto/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
